import React from 'react';
import check from "../../img/check.png";

interface BoxPerksProps{
    text:string
}

const BoxPerks = (props:BoxPerksProps) => {
  return (
        <div className='perks-box'>
            <div>
                <img
                src={check}
                alt="Check Why Select MyWineQr"
                width="32"
                height="32" />
            </div>

            <p className='perk-desc'>{props.text}</p>
            
        </div>
  )
}

export default BoxPerks
