import React from 'react'
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';

const RequiredData = () => {

    const { t } = useTranslation();
    
    let data = [
        i18next.t("req-el-1"),
        i18next.t("req-el-2"),
        i18next.t("req-el-3"),
        i18next.t("req-el-4"),
        i18next.t("req-el-5"),
        i18next.t("req-el-6"),
        i18next.t("req-el-7"),
        i18next.t("req-el-8"),
        i18next.t("req-el-9"),

    ]
  return (
    <div className='req-section'>
    
        <div className='req-img-box' >
            <img src={require(`../../img/cphone-${i18next.language}.png`)}  alt="Phone"/>
       </div>
       <div className='req-text-box' >
           
            <h1 className='req-title'>{t("req-title")}</h1>
            <p className='req-desc'>{t("req-desc")}</p>

            <div className='req-features'>
                <img  className='phone-req' src={require(`../../img/phone-${i18next.language}.png`)}  alt="Phone"/>

                <div className='req-features-box'>
                    {data.map((val:any, index) => {
                        return <p className='req-feature' key={index} >{val}</p>
                    })}
                </div>

            </div>
       </div>

    </div>
  )
}

export default RequiredData
