import React from 'react';
import BoxPerks from './BoxPerks';

const SectionPerks = () => {

    let data = [
        "Prikaz verodostojnih in transparentnih informacij za potrošnike na enem mestu",
        "Ena QR koda ze eno vino, dostopna v več jezikih",
        "Hitro in enostavno - od QR kode do etikete"
    ];

    return (
        <div className='perks-section'>
            <h1 className='perks-header'>Zakaj izbrati MyWineQR?</h1>
            <div className="elements-box" >

                {data.map((val:any, index) => {
                    return <BoxPerks key={index} text={val}/>
                })}

            </div>
        </div>
    )
}

export default SectionPerks
