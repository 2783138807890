import React, {useState} from "react";
import i18n from '../i18n';

const LanguageSelector = (props) => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("lang") ?? i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.
    //console.log("Selected lang ", selectedLanguage);


    const chooseLanguage = (e) => {
        return (event) => {

            event.preventDefault();
            i18n.changeLanguage(e);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
            setSelectedLanguage(e);
            localStorage.setItem("lang", e);
        }
    }

    return (<>

        <div className="justify-end flex flex-row gap-2 text-defaultText font-poppins font-light text-sm leading-6 cursor-pointer"  >
            <p className={ (selectedLanguage === "sl" ? ' font-bold ' : "") + " cursor-pointer"}  onClick={chooseLanguage("sl")}>SLO</p>
            <p>/</p>
            <p className={ (selectedLanguage === "en" ? ' font-bold ' : "") + " cursor-pointer"}  onClick={chooseLanguage("en")}>EN</p>
        </div>


        {/* someting is newthat you shout  update */}

    </>);
};

export default LanguageSelector;