import React from 'react'
import img1 from "../../img/about.png";

const About = () => {
  return (
    <div className='about-section'>
       <div className='about-text-box' >
            <div>
                <h1 className='about-title'>Zakaj potrebujete QR kodo na vinski etiketi?</h1>
                <p className='about-desc'>Leta 2023 je EU uvedla strožje zahteve za označevanje vina, ki zahtevajo, da so podatki o lastnostih, poreklu in sestavi vina potrošnikom dostopni v kar 24 jezikih. Zaradi omejenega prostora na etiketi je QR koda najboljša rešitev, saj potrošnika preusmeri na spletno mesto z vsemi potrebnimi podatki, hkrati pa ohranja estetsko zasnovo etikete.</p>
            </div>
       
            <div>
                <h1 className='about-title' >Katere informacije naj QR koda vsebuje?</h1>
                <p className='about-desc'>Leta 2023 je EU uvedla strožje zahteve za označevanje vina, ki zahtevajo, da so podatki o lastnostih, poreklu in sestavi vina potrošnikom dostopni v kar 24 jezikih. Zaradi omejenega prostora na etiketi je QR koda najboljša rešitev, saj potrošnika preusmeri na spletno mesto z vsemi potrebnimi podatki, hkrati pa ohranja estetsko zasnovo etikete.</p>
            </div>
        
       </div>

       <div className='about-img-box' >
        <img
            src={img1}
            alt="Wine" />
       </div>

    </div>
  )
}

export default About
