import React from 'react';
import Header from './Components/Header/Header';
import Body from './Components/Body/Body';
import Footer from './Components/Footer/Footer';
import { Flowbite } from 'flowbite-react';

function App() {
    return (
        <Flowbite >
            <div className="w-full flex min-h-screen flex-col items-center bg-white font-sans dark:bg-gray-900">
                <Header />
                <Body  />
                <Footer  />
            </div>
        </Flowbite>
    );
}

export default App;
