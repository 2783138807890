import React from 'react'
import img1 from "../../img/phone.png";

const RequiredData = () => {
    let data = [
        "Kategorija vina",
        "Vsebnost sladkorja",
        "Država / območje porekla",
        "Morebitni alergeni",
        "Vsebnost alkohola",
        "Informacije o energijski vrednosti",
        "Podatki o polnilcu", 
        "Dostopnost v 24 jezikih",
        "Podatki o uvozniku"

    ]
  return (
    <div className='req-section'>
    
        <div className='req-img-box' >
            <img
                src={img1}
                alt="Phone" />
       </div>
       <div className='req-text-box' >
           
            <h1 className='req-title'>Obvezni podatki na QR kodi</h1>
            <p className='req-desc'>Nove zahteve so dodatek k obstoječim predpisom, zato morate na QR kodi obvezno zagotoviti sledeče podatke:</p>

            <div className='req-features'>

                <img
                className='phone-req'
                src={img1}
                alt="Phone" />

                <div className='req-features-box'>
                    {data.map((val:any, index) => {
                        return <p className='req-feature' key={index} >{val}</p>
                    })}
                </div>

            </div>
       </div>

    </div>
  )
}

export default RequiredData
