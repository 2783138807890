import React from 'react'
import { useTranslation } from 'react-i18next';

const WhyMyWineQr = () => {
    const { t } = useTranslation();
    return (
        <div className='why-section'>
            <h1 className='why-title'>{t("why-mywine-title")}</h1>
            <p className='why-desc'>{t("why-mywine-desc")}
            </p>
        </div>
    )
}

export default WhyMyWineQr
