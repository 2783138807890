import React from 'react'
//import img1 from "../../img/landing3.png";
//import img2 from "../../img/landing3-eng.png";
//import img1 from `../../img/landing_${i18next.language}.png;`

import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';

const LandingSection = () => {
    const { t } = useTranslation();

    return (
        <div className='landing-section'>
        <div className='text-box' >
                <h1 className='landing-title'>{t("landing-title")}</h1>
                <p className='landing-desc'>{t("landing-desc")}</p>
        </div>

        <div className='landing-img-box' >

            <img src={require(`../../img/landing-${i18next.language}.png`)}  alt="wine"/>
 
        </div>
        </div>
    )
}

export default LandingSection
