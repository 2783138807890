import React from 'react'
import logo from "../../img/logo.png";

const Header = () => {
  return (
    <header className="h-[104px] sticky top-0 z-10 flex w-full justify-center bg-primary-50 " 
        style={{ boxShadow: `0px 4px 12px 4px #0000001F` }}>
        <div className="flex w-[1440px] flex-col gap-8 py-8 px-8 ">
            <div className="flex items-center justify-between">
                <div className="flex gap-6">
                    <img
                        src={logo}
                        alt="MyWineQr Logo"
                        width="71"
                        height="128" />
                </div>

                <div className="justify-end flex flex-row gap-4">
                   
                    {/*<button className="button-transparent border border-white dark:text-gray-200 dark:border-gray-200" >
                        SLO / ENG
                    </button>*/}
                    
                </div>
            </div>
         
        </div>
    </header>
  )
}

export default Header
