import { useEffect, useState } from "react"

declare global {
    interface Window {
      captchaOnLoad: () => void
      grecaptcha: ReCaptchaInstance
    }
  }

  
interface ReCaptchaInstance {
    ready: (cb: () => any) => any
    execute: (options: ReCaptchaExecuteOptions) => Promise<string>
    render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
    action: string
}

interface ReCaptchaRenderOptions {
    sitekey: string
    size: 'invisible'
}

export default function useReCaptcha(sitekey: string): (action: string) => Promise<string>  {

    const [ready, setReady] = useState(false);

    useEffect(() => {
        window.captchaOnLoad = function() {
            if(!document.getElementById('g-recaptcha')) {
                const widget = document.createElement('div')
                widget.id = 'g-recaptcha'
                document.body.appendChild(widget)
                window.grecaptcha.render("g-recaptcha", {
                    sitekey: sitekey,
                    size: 'invisible'
                })
                window.grecaptcha.ready(() => {
                    setReady(true);
                })
            }
        }

    
        const url = 'https://www.google.com/recaptcha/api.js'
        const queryString = '?onload=captchaOnLoad&render=explicit'
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url + queryString
        script.async = true
        script.defer = true

        document.body.appendChild(script)

        return function() {
            let node = document.getElementById('g-recaptcha');
            if(node) {
                node.remove();
            }
            script.remove();
        }
    }, [])


    return async function(action: string): Promise<string> {
        if(!ready) {
            throw new Error("Captcha can be executed only when it's ready.")
        }
        return window.grecaptcha.execute({ action: action })
    }

}  