import React from 'react'

import item_1 from "../../img/item_1.png";
import item_2 from "../../img/item_2.png";
import item_3 from "../../img/item_3.png";

import arrow from "../../img/icon_arrow.png";

const HowItWorks = () => {
  return (
    <div className='how-section'>
        <h1 className='how-title'>Kako MyWineQR deluje?</h1>
        <p className='how-desc'>Postopek je hiter in enostaven. Vi izpolnite podatke, naš sistem pa samodejno generira QR kodo, ki jo lahko posredujete tiskarju za umestitev na etiketo, ali pa vas povežemo s ponudnikom. Potrošnik QR kodo preprosto skenira, povezava pa ga pripelje do spletne strani z vsemi informacijami. V prihodnosti boste lahko to spletno stran prilagodili videzu vaše blagovne znamke.</p>
    
        <div className='how-elements-box'>
            <div className='item-box'>
                <img 
                src={item_1}
                height="40"
                width="40"
                alt="Item 1" />
                <p>Ustvarite profil vinarne</p>
            </div>

            <img
                src={arrow} 
                className='item-arrow'
                alt="Item arrow" 
            />

            <div className='item-box'>
                <img
                    src={item_2}
                    height="40"
                    width="40"
                    alt="Item 2" />
                <p>Izpolnite potrebne podatke za QR kodo</p>
            </div>

            <img
                src={arrow}
                className='item-arrow'
                alt="Item arrow" 
            />

            <div className='item-box'>
                <img
                src={item_3}
                height="40"
                width="40"
                alt="Item 3" />
                <p>Samodejno generirajte QR kodo</p>
            </div>
        </div>
    </div>
  )
}

export default HowItWorks
