import React from 'react'

const WhyMyWineQr = () => {
  return (
    <div className='why-section'>
        <h1 className='why-title'>Kako se MyWineQR razlikuje od drugih?</h1>
        <p className='why-desc'>MyWineQR vam omogoča, da z ustvarjanjem QR kode hkrati vzpostavite identiteto svoje vinarne in zagotovite verodostojnost podatkov. Naša rešitev uporablja napredno kriptografsko zaščito, ki zagotavlja, da podatki ostanejo nespremenljivi in so povezani z vašo blagovno znamko, kar povečuje zaupanje potrošnikov. MyWineQR je prav tako tehnološko skladna s prihajajočimi zahtevami za digitalne potne liste produktov. Z našimi QR kodami boste že danes korak pred konkurenco!</p>
    </div>
  )
}

export default WhyMyWineQr
