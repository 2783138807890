import React from 'react'
import SectionPerks from './SectionPerks'
import ContactForm from './ContactForm'
import LandingSection from './LandingSection'
import About from './About'
import RequiredData from './RequiredData'
import HowItWorks from './HowItWorks'
import WhyMyWineQr from './WhyMyWineQr'


const Body = () => {
    return (<>
        <div className="relative mt-10 flex gap-6 flex-col landing-box">
            <LandingSection/>
            <SectionPerks/>
            <ContactForm/>
            <About/>
            <RequiredData/>
            <HowItWorks/>

            <WhyMyWineQr/>
        </div>  
        
        
    </>)
}

export default Body
