import React from 'react'
import img1 from "../../img/landing3.png";

const LandingSection = () => {
  return (
    <div className='landing-section'>
       <div className='text-box' >
            <h1 className='landing-title'>Enostavno do QR kod za vino, skladnih z EU predpisi</h1>
            <p className='landing-desc'>Ustvarite in upravljajte QR kode, ki vsebujejo vse potrebne podatke za skladnost z novo uredbo.</p>
       </div>

       <div className='landing-img-box' >
            <img
                src={img1}
                alt="Wine" />
       </div>
    </div>
  )
}

export default LandingSection
