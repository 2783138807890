import React from 'react'
import img1 from "../../img/about.png";
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className='about-section'>
        <div className='about-text-box' >
                <div>
                    <h1 className='about-title'>{t("about-why-title")}</h1>
                    <p className='about-desc'>{t("about-why-desc")}</p>
                </div>
        
                <div>
                    <h1 className='about-title' >{t("about-info-title")}</h1>
                    <p className='about-desc'>{t("about-info-desc")}</p>
                </div>
            
        </div>

        <div className='about-img-box' >
            <img
                src={img1}
                alt="Wine" />
        </div>

        </div>
    )
}

export default About
