import React from 'react';
import BoxPerks from './BoxPerks';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';

const SectionPerks = () => {

    const { t } = useTranslation();

    let data = [
        i18next.t("perks-el-1"),
        i18next.t("perks-el-2"),
        i18next.t("perks-el-3"),
    ];

    return (
        <div className='perks-section'>
            <h1 className='perks-header'>{t("perks-title")}</h1>
            <div className="elements-box" >

                {data.map((val:any, index) => {
                    return <BoxPerks key={index} text={val}/>
                })}

            </div>
        </div>
    )
}

export default SectionPerks
