import React, { useState } from 'react'
import useReCaptcha from '../Recaptcha';
import { config } from '../../config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
    message: "",
    title:"Contact information from MyWineQr website",
    name:"",
    email:"",
}

let serverUrl = window.location.origin;

const ContactForm = () => {

    const getRecaptchaToken = useReCaptcha(config.recaptchaToken);
    const [values, setValues] = useState(initialValues);

    const notifyError = (err:any) => {
        toast.error(err,{theme:"colored"}); 
    }
    const notify = (err:any) => {
        toast.success(err,{theme:"colored"}); 
    }

    const handleInputChange = (e:any) => {
        var { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };

    const handleSubmit = (e:any) => {
        e.preventDefault();

        getRecaptchaToken("submit").then((token) => {
    
            const headers: HeadersInit = { 'Content-Type': 'application/json', 'X-Recaptcha-Token': token}
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(values)
            };

            fetch(serverUrl  + config.emailApi, requestOptions).then(async response => {

                // check for error response
                if (!response.ok ) {
                    // get error message from body or default to response status
                    console.log("Error in response code")
                    const error = await response.text();
                    return Promise.reject(error);

                }else{

                    const data1 = await response.text();
                    //console.log("recieved", data1);
                    const data = JSON.parse(data1);
                    
                    if(data.success) {
                        
                        try {
                           //setFinishText("Thank you for taking time to reach out.");
                           console.log("sent email");
                           setValues({message: "",  title:"Contact information from MyWineQr website", name:"", email:"",});
                           notify("Email sent successfully.");

                        } catch (error:any) {
                            throw new Error(error.toString());
                        }
                        
                    } else {
                        throw new Error(data1);
                    }
                }
            })
            .catch(error => {
                console.error("Error in API Issue: ", error);
                notifyError(error.toString());

            });
        }) 
    }

    return (
        <div className='contact-section'>
            
            <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
            className="toast"
            />

            <div>
                <form action="/" className="form" onSubmit={handleSubmit}>

                    <div className='form-box' >
                        <h1 className='form-title'>Oglasite se nam.</h1>
                        <div className="input-wrapper" >
                            <input  className="form-input focus:ring-0 focus:border-primary-900" type="text"  maxLength={60}  placeholder="e-naslov" value={values.email}  onChange={handleInputChange} name="email" />
                            <input  className="form-input focus:ring-0 focus:border-primary-900" type="text" maxLength={60} placeholder="ime podjetja"  value={values.name}  onChange={handleInputChange} name="name" />
                        </div>
                    </div>

                    <div className='form-box'>
                        <div className='form-desc'>
                            <p>Želite QR kodo tudi na vašem vinu? </p>
                        </div>

                        <div className='form-msg-box'>
                            <p>Sporočilo</p>
                            <textarea className="focus:ring-0 focus:border-primary-900" id="feedback_form" required maxLength={600} placeholder="" name="message" value={values.message} onChange={handleInputChange}/>
                            
                            <div>
                                <button className="form-btn">POŠLJITE</button>
                            </div>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    )
}

export default ContactForm
