import React from 'react'
import footerLogo from "../../img/MyWineQR_Icon_Dark.png"

const Footer = () => {
  return (
    <footer className="h-auto w-full bg-primary-50 flex dark:bg-gray-800">
        <div className="w-full flex justify-center p-8">   
            <img
                src={footerLogo}
                alt="MyWineQr Logo"
                width="32"
                height="40" />
        </div>
    </footer>
  )
}

export default Footer
